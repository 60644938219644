// Modules
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { navigate } from 'gatsby-link'
import PropTypes from 'prop-types'
import ReCaptcha from 'react-google-recaptcha'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import React, {
  useEffect,
  useState,
} from 'react'

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Data
import sitePhoneNumber from 'data/cms-data/site-phone-number'

// Styles
const FormStyles = styled.div`
  margin: 0 auto;
  background-color: ${props => props.theme.color.white}1A;
  padding: ${props => props.theme.space.betweenSections} ${props => props.theme.space.betweenColumns};
  border: solid 2px ${props => props.theme.color.white}; 
  border-radius: 5px;

  h3 {
    margin-top: 0;
    text-align: center;
  }
  
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;

    &:disabled label {
      cursor: not-allowed;
    }
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field-wrapper,
  .contact-recaptcha {
    margin-bottom: 1.5rem;
  }

  .form-field-wrapper {
    position: relative;
    width: 100%;
    max-width: 300px;

    label {
      position: relative;
      display: block;
      margin: 20px auto 0;
      cursor: text;
    }

    input,
    textarea {
      width: 100%;
      height: 30px;
      background-color: transparent;
      font-family: ${props => props.theme.font.montserrat};
      color: ${props => props.theme.color.white};
      font-weight: 500;
      border: 0;
      border-bottom: solid 2px ${props => props.theme.color.white};
      transition: all 0.3s;

      &.error {
        border-color: ${props => props.theme.color.fail};

        & + .label-text { color: ${props => props.theme.color.fail}; } 
      }

      &:-webkit-autofill + .label-text, 
      &.valid + .label-text,
      &:focus + .label-text, 
      &.valid + .area-label-text,
      &:focus + .area-label-text {
        top: -20px;
        font-size: .67rem;
        letter-spacing: 1px;
      }
    }

    input { font-size: 1rem; }
    textarea {
      margin-bottom: 50px;
      font-size: .75rem;
      resize: none;

      &.valid,
      &:focus {
        height: 80px;
        margin-bottom: 0;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus {
      -webkit-text-fill-color: ${props => props.theme.color.white};
      -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.color.white}00 inset;
      box-shadow: 0 0 0px 1000px ${props => props.theme.color.white}00 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .error-message {
      position: absolute;
      width: 100%;
      margin-top: 0;
      color: ${props => props.theme.color.fail};
      font-size: .75rem;
      text-align: center;
    }
    
    .label-text,
    .area-label-text {
      position: absolute;
      top: 0;
      transition: all 0.3s;

      &.required::after { content: '*'; }
    }
  }

  .bot-field { display: none; }
  .submit-button-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .button,
    .sending,
    .disabled-message {
      width: 100%;
    }

    .button,
    .sending {
      max-width: 300px;
    }

    .disabled-message {
      text-align: center;
      color: ${props => props.theme.color.warn};
    }

    .sending {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 61px;
      margin: 0 auto;

      .sending-icon { margin-right: 1rem; }
    }
  }
`

// Variables
const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
const phonePattern = /^(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})/
const formName = 'contact'
const isDev = process.env.NODE_ENV === 'development'
// PropTypes
const propTypes = {
  contactForm: PropTypes.shape({
    email: PropTypes.shape({
      label: PropTypes.string.isRequired,
      pattern: PropTypes.string.isRequired,
      required: PropTypes.string.isRequired,
    }),
    firstname: PropTypes.shape({
      label: PropTypes.string.isRequired,
      maxLength: PropTypes.string.isRequired,
      required: PropTypes.string.isRequired,
    }),
    header: PropTypes.string,
    lastname: PropTypes.shape({
      label: PropTypes.string.isRequired,
      maxLength: PropTypes.string.isRequired,
      required: PropTypes.string.isRequired,
    }),
    message: PropTypes.shape({ label: PropTypes.string.isRequired }),
    phone: PropTypes.shape({
      label: PropTypes.string.isRequired,
      pattern: PropTypes.string.isRequired,
    }),
    submit: PropTypes.shape({
      default: PropTypes.string.isRequired,
      disable: PropTypes.string.isRequired,
      fail: PropTypes.string.isRequired,
      retry: PropTypes.string.isRequired,
      sending: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
    }),
    failureMessage: PropTypes.string.isRequired,
  }),
  location: PropTypes.shape({ search: PropTypes.string }),
}

const ContactForm = ({ contactForm }) => {

  const {
    email,
    firstname,
    header,
    lastname,
    message,
    phone,
    submit,
    failureMessage,
  } = contactForm

  const { phoneNumber } = sitePhoneNumber
  const [recaptchaValue, setRecaptchaValue] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [buttonClass, setButtonClass] = useState('primary')
  const [buttonText, setButtonText] = useState(submit.default)
  const [hasContactRequest, setHasContactRequest] = useState(false)
  const [failedAttemts, setFailedAttemts] = useState(0)
  const [disableForm, setDisableForm] = useState(false)
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm({ mode: 'onBlur' })

  useEffect(() => {

    if (typeof window !== 'undefined') {
      const form = document.querySelector('form')
      form && form.reset()
    }

    const contactReason = new URLSearchParams(window.location.search)
      .get('cr')

    if (contactReason) {
      let messageValue
      const messageField = document.getElementById('message')

      if (contactReason === 'deal')
        messageValue = `I would like to hear more about the deal(s) your currently offering.`

      if (contactReason.includes('-program')) {
        const splitContactReason = contactReason.split('-')
        splitContactReason.pop()

        const CapitalizedSplitContactReason = splitContactReason.map(word => word.charAt(0).toUpperCase() + word.slice(1))
        messageValue = `I would like to hear more about your "${CapitalizedSplitContactReason.join(' ')}" program.`
      }

      if (!messageValue)
        return

      messageField.value = messageValue
      setHasContactRequest(true)
    }
  }, [])

  const isEmailError = errors?.email?.type === 'required' || errors?.email?.type === 'pattern'
  const isFirstNameError = errors?.firstName?.type === 'required' || errors?.firstName?.type === 'maxLength'
  const isLastNameError = errors?.lastName?.type === 'required' || errors?.lastName?.type === 'maxLength'
  const isPhoneNumberError = errors?.phoneNumber?.type === 'pattern'
  const encode = formData => Object.keys(formData)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
    .join('&')

  const onSubmit = formData => {
    if (disableForm)
      return

    setIsSubmitting(true)

    fetch('/', {
      body: encode({
        ...formData,
        'g-recaptcha-response': recaptchaValue,
      }),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    })
      .then(() => {
        setTimeout(() => {
          setButtonText(submit.success)
          setIsSubmitting(false)
          setButtonClass('success')

          setTimeout(() => {
            navigate('/contact/thanks')
          }, 250)
        }, 2000)
      })
      .catch(() => {
        setIsSubmitting(false)
        setButtonText(submit.fail)
        setButtonClass('fail')

        if (failedAttemts < 3)
          setFailedAttemts(failedAttemts + 1)

        setTimeout(() => {
          if (failedAttemts >= 3) {
            setDisableForm(true)
            setButtonClass('disabled')
            setButtonText(submit.disable)
          } else {
            setButtonClass('retry')
            setButtonText(submit.retry)
          }
        }, 2000)
      })
  }

  return (
    <FormStyles>
      {header && <h3>{header}</h3>}
      <form
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        data-netlify-recaptcha='true'
        method='post'
        name={formName}
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset disabled={disableForm} >
          <div className='form-fields'>
            <input
              type='hidden'
              value={formName}
              {...register('form-name')}
            />

            <div className='bot-field'>
              <label htmlFor='bot-field'>Don’t fill this out if you’re human</label>
              <input
                id='bot-field'
                name='bot-field'
              />
            </div>

            <div className='form-field-wrapper'>
              <label htmlFor='firstName'>
                <input
                  className={`${isFirstNameError && 'error'} ${watch('firstName') && 'valid'}`}
                  id='firstName'
                  type='text'
                  {...register('firstName', {
                    maxLength: 50,
                    required: true,
                  })}
                />
                <div className='label-text required'>{firstname.label}</div>
                {errors?.firstName?.type === 'required' && <p className='error-message'>{firstname.required}</p>}
                {errors?.firstName?.type === 'maxLength' && <p className='error-message'>{firstname.maxLength}</p>}
              </label>
            </div>

            <div className='form-field-wrapper'>
              <label htmlFor='lastName'>
                <input
                  className={`${isLastNameError && 'error'} ${watch('lastName') && 'valid'}`}
                  id='lastName'
                  type='text'
                  {...register('lastName', {
                    maxLength: 50,
                    required: true,
                  })}
                />
                <div className='label-text required'>{lastname.label}</div>
                {errors?.lastName?.type === 'required' && <p className='error-message'>{lastname.required}</p>}
                {errors?.lastName?.type === 'maxLength' && <p className='error-message'>{lastname.maxLength}</p>}
              </label>
            </div>

            <div className='form-field-wrapper'>
              <label htmlFor='phone'>
                <input
                  className={`${isPhoneNumberError && 'error'} ${watch('phoneNumber') && 'valid'}`}
                  id='phone'
                  type='tel'
                  {...register('phoneNumber', { pattern: phonePattern })}
                />
                <div className='label-text'>{phone.label}</div>
                {errors?.phoneNumber?.type === 'pattern' && <p className='error-message'>{phone.pattern}</p>}
              </label>
            </div>

            <div className='form-field-wrapper'>
              <label htmlFor='email'>
                <input
                  className={`${isEmailError && 'error'} ${watch('email') && 'valid'}`}
                  id='email'
                  type='email'
                  {...register('email', {
                    pattern: emailPattern,
                    required: true,
                  })}
                />
                <div className='label-text required'>{email.label}</div>
                {errors?.email?.type === 'required' && <p className='error-message'>{email.required}</p>}
                {errors?.email?.type === 'pattern' && <p className='error-message'>{email.pattern}</p>}
              </label>
            </div>

            <div className='form-field-wrapper'>
              <label htmlFor='message'>
                <textarea
                  className={watch('message') || hasContactRequest ? 'valid' : ''}
                  id='message'
                  type='text'
                  {...register('message')}
                />
                <div className='area-label-text'>{message.label}</div>
              </label>
            </div>

            {
              !isDev && (
                <ReCaptcha
                  className='contact-recaptcha'
                  sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                  onChange={setRecaptchaValue}
                />
              )
            }
          </div>

          <div className='submit-button-wrapper'>
            {
              isSubmitting
                ? (
                  <div className='sending'>
                    <span className='sending-icon'>
                      <FontAwesomeIcon
                        spin
                        icon={faCircleNotch}
                      />
                    </span>
                    <span>{submit.sending}</span>
                  </div>
                )
                : (
                  <>
                    <button
                      className={`button ${buttonClass}`}
                      disabled={disableForm}
                      type='submit'
                    >
                      {buttonText}
                    </button>
                    {disableForm && <p className='disabled-message'>{`${failureMessage}: ${phoneNumber}`}</p>}
                  </>
                )
            }
          </div>
        </fieldset>
      </form>
    </FormStyles>
  )
}

ContactForm.propTypes = propTypes
export default ContactForm
