// Modules
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

// Components
import ContactForm from 'components/ContactForm'
import Container from 'components/global-components/Container'
import ReactMarkdown from 'react-markdown'

// Scripts
import {
  largeUp,
  mediumUp,
} from 'scripts/media-query'

// Styles
const ContactPageStyles = styled.div`
  flex-grow: 1;
  padding: 164px 0 ${props => props.theme.space.betweenSections};
  background-color: ${props => props.theme.color.grey};
  color: ${props => props.theme.color.white};

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${mediumUp} { flex-direction: row; }
    ${largeUp} { align-items: center; }
  }

  .contact-content,
  .contact-form {
    width: 100%;
    margin-bottom: ${props => props.theme.space.betweenSections};
    
    ${mediumUp} {
      width: 50%;
      margin-bottom: 0;
    }
  }

  .contact-content {
    margin-right: 0;
    
    ${mediumUp} {
      margin-right: ${props => props.theme.space.betweenColumns};
    }
  }
  
  .contact-form {
    margin-left: 0;
    
    ${mediumUp} {
      margin-left: ${props => props.theme.space.betweenColumns};
    }
  }
`

// PropTypes
const propTypes = {
  contactForm: PropTypes.objectOf(PropTypes.any).isRequired,
  contactInfoSection: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    header: PropTypes.string.isRequired,
  }),
}

const ContactBody = ({
  contactForm,
  contactInfoSection,
}) => {

  const {
    content,
    header,
  } = contactInfoSection

  return (
    <ContactPageStyles>
      <Container>
        <div className='contact-container'>
          <section className='contact-content'>
            <h1>{header}</h1>
            <ReactMarkdown>{content}</ReactMarkdown>
          </section>
          <section className='contact-form'>
            <ContactForm contactForm={contactForm} />
          </section>
        </div>
      </Container>
    </ContactPageStyles>
  )
}

ContactBody.propTypes = propTypes
export default ContactBody
