// Modules
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

// Components
import ContactBody from 'components/page-bodies/ContactBody'
import Layout from 'components/global-components/Layout'
import MetaData from 'components/global-components/MetaData'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        contactForm: PropTypes.objectOf(PropTypes.any).isRequired,
        contactInfoSection: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
        metadata: PropTypes.objectOf(PropTypes.any),
      }),
    }),
  }),
}

const ContactPageTemplate = ({ data }) => {

  const {
    markdownRemark: {
      frontmatter: {
        contactForm,
        contactInfoSection,
        metadata,
      },
    },
  } = data

  return (
    <Layout>
      <MetaData metaData={metadata} />
      <ContactBody
        contactForm={contactForm}
        contactInfoSection={contactInfoSection}
      />
    </Layout>
  )
}

ContactPageTemplate.propTypes = propTypes
export default ContactPageTemplate
export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "contact-page"}}) {
      frontmatter {
        metadata {
          description
          title
          robots {
            follow
            index
          }
          ogImage {
            childImageSharp {
              fluid {
                originalImg
                originalName
              }
            }
          }
        }
        contactInfoSection {
          header
          content
        }
        contactForm {
          header
          email {
            label
            pattern
            required
          }
          firstname {
            label
            maxLength
            required
          }
          lastname {
            label
            maxLength
            required
          }
          message {
            label
          }
          phone {
            label
            pattern
          }
          submit {
            default
            disable
            fail
            retry
            sending
            success
          }
          failureMessage
        }
      }
    }
  }
`
